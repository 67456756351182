<template>
  <div style="min-height:100vh">
    <div class="body">
      <div class="header">《奈可狮尼用户行为规范》</div>
      <div>
 <p>第一章 概述</p>
      <p>第一条 为维护奈可狮尼公平、诚信、和谐等正常的商业运营秩序，以更好地支持合作伙伴，保障买卖双方的合法权益，在《奈可狮尼商城用户协议》（点击可直接跳转至协议页面查看协议内容）的基础上，特制定本规范。</p>
      <p>第二条 根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，奈可狮尼运营总部（即五渔地生源（上海）贸易有限公司，以下简称五渔村）可能变更本规范、补充规范及附件，并在奈可狮尼商城小程序、社群等渠道以公告或其他形式通知奈可狮尼用户，用户应及时查看。该行为视为奈可狮尼已经通知用户修改内容，修改后的内容一经公布生效后即替代原有规范内容。如奈可狮尼用户不同意相关变更，应立即停止使用相应服务，并应及时通知五渔村；如奈可狮尼用户继续使用相应服务的，则视为同意变更后的规范。五渔村有权对会员行为按照相关适用的规范、规则、约定独立作出认定，并据此处理。</p>
      <p>第二章 名词定义</p>
      <p>第一条 奈可狮尼运营总部：指五渔地生源（上海）贸易有限公司。</p>
      <p>第二条 奈可狮尼商城小程序：指由五渔村研发的手机导购小程序-奈可狮尼商城</p>
      <p>第三条 奈可狮尼：指依托奈可狮尼商城小程序研发的用户可通过购买商品的平台</p>
     <p> 第四条 奈可狮尼用户：指在奈可狮尼商城小程序进行注册、登录并通过奈可狮尼商城内的奈可狮尼平台进行购买产品的人群。</p>
      <p>第三章 通用规范</p>
     <p> 本章节规定了奈可狮尼用户在使用、推广奈可狮尼时应遵守的通用规范。</p>
      <p>第一条 奈可狮尼商城小程序是五渔村研发的、用于进行产品推广服务的导购平台，因此用户在遵循“奈可狮尼商城”小程序的相关规范外，须严格遵守五渔村相应的规则（包括不断更新的规则）。</p>
      <p>第二条 奈可狮尼用户应严格遵守五渔村相关管理和业务规定，不得存在如下任一行为：</p>
      <p>2.1 未经授权直接使用五渔村或奈可狮尼品牌。如推广渠道内有五渔村或奈可狮尼品牌且有相应链接页面的，则链接页面必须指向相应的官方首页，不得指向其他任何页面。</p>
      <p>2.2 未经授权使用与五渔村、奈可狮尼品牌及相关管理层等相同或相似的名称、域名、品牌、对外宣传用语等，对奈可狮尼用户有误导嫌疑的。</p>
      <p>2.3 恶意诋毁、贬损或攻击五渔村及其管理层、相关业务的。</p>
      <p>2.4 违反五渔村现有规则的。</p>
      <p>2.5 侵犯五渔村、奈可狮尼品牌或其他用户利益的、直接或间接导致上述组织或人员损失的行为。</p>
      <p>第三条 奈可狮尼用户不得存在如下任一情形：</p>
      <p>3.1 出现违反国家法律法规、行政规章等规定，或违反公序良俗的情形。</p>
      <p>3.2 仿冒品牌官网进行推广，或使用其他令用户误认为获得品牌官方授权的描述、介绍性用语进行推广。</p>
      <p>3.3利用奈可狮尼官方名义开设收费课程（培训、招商等形式）。</p>
      <p>第四条 常规交易违规行为（包括但不限于以下具体行为）</p>
      <p>4.1 交易异常：奈可狮尼用户带来的交易中，异常订单多，损害卖家利益、影响平台正常秩序。</p>
      <p>异常订单包括但不限于以下情形：</p>
      <p>4.1.1 买家利用退款流程的便利性，购买商品获得相关实物利益或虚拟利益后发起退款。</p>
      <p>常见形式如物流拒签、货物退回、线下退款，或者线上维权失败通过其他方式退款等。</p>
      <p>4.1.2 买家按原价或高价格拍下商品并引导卖家通过其他方式退差价。</p>
      <p>第五章 违规认定与处理</p>
      <p>奈可狮尼用户应以诚实信用为原则，严格遵守本规范之规定。为构建和谐健康的平台环境，奈可狮尼将按相关法律法规进行处理。</p>
      <p>第六章 附则</p>
      <p>第十九条 本规范的标题仅为方便阅读所设，非对具体条款内容的定义、限制、解释或描述其范围或界限。</p>
      <p>第二十条 本规范未作定义的词语或术语，如在五渔村现有规则已有定义的，适用其定义；如未进行定义的，按照上下文意思及该词语或术语的通常含义进行理解与解释。</p>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  created() {
   
    this.$store.commit("saveTypeIndex", 9);
  },
  methods: {}
};
</script>

<style scoped>
.body {
  width: 1000px;
  margin: 30px auto;
}
.header {
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
}
</style>

